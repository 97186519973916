import React, { useContext } from "react"
import * as Styles from "../../style/home.module.scss";
import { Row, Container, Col, Tab, Nav, Button, Form, Accordion, AccordionContext, Card, useAccordionToggle, useAccordionButton } from "react-bootstrap";
import { Icon } from 'react-icons-kit'
import { caretRight } from 'react-icons-kit/fa/caretRight'
const CustomAccordian = () => {

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
          );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <div>
                {isCurrentEventKey ?
                    <button className={Styles.accordionButton} onClick={decoratedOnClick}>
                        <div className={Styles.dSectionHeading}>
                            <p className="mb-0"> {children}</p>
                            <Icon icon={caretRight} className={Styles.caretRightIcon} size={75.5} />
                        </div>
                    </button>
                    :
                    <button
                        type="button"
                        className={`${Styles.accordionButton} ${Styles.list}`}
                        onClick={decoratedOnClick}
                 >
                        {children}
                    </button>
                }
            </div>
        );
    }

    return (
        <Accordion defaultActiveKey="0">


            <ContextAwareToggle eventKey="0">Attendance Integration</ContextAwareToggle>

            <Accordion.Collapse eventKey="0">
                <p className={Styles.colorGrey} style={{ padding: 10, fontSize: 14, }}>Register and start enrolling your on the platform <br />immediately. No more waiting for attendance <br />integration/installation support</p>
            </Accordion.Collapse>


            <ContextAwareToggle eventKey="1">Feature 2</ContextAwareToggle>

            <Accordion.Collapse eventKey="1">
                <p className={Styles.colorGrey} style={{ padding: 10, fontSize: 14, }}>Hello! I'm another body</p>
            </Accordion.Collapse>

            <ContextAwareToggle eventKey="2">Feature 3</ContextAwareToggle>

            <Accordion.Collapse eventKey="2">
                <p className={Styles.colorGrey} style={{ padding: 10, fontSize: 14, }}>Hello! I'm another body</p>
            </Accordion.Collapse>

            <ContextAwareToggle eventKey="3">Feature 4</ContextAwareToggle>

            <Accordion.Collapse eventKey="3">
                <p className={Styles.colorGrey} style={{ padding: 10, fontSize: 14, }}>Hello! I'm another body</p>
            </Accordion.Collapse>

            <ContextAwareToggle eventKey="4">Feature 5</ContextAwareToggle>

            <Accordion.Collapse eventKey="4">
                <p className={Styles.colorGrey} style={{ padding: 10, fontSize: 14, }}>Hello! I'm another body</p>
            </Accordion.Collapse>

        </Accordion>
    );
}


export default CustomAccordian